<template>
  <CCard class="mb-0 events">
    <CCardHeader v-if="showHeader" class="pb-0">
      <CRow>
        <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
          <span>{{$t('loyalty.Benefits_moments')}}</span>
        </CCol>
        <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
          <div class="mt-1 mt-xl-0 d-flex align-items-start justify-content-xl-end">
            <CButton v-if="checkPermission('loyalty.benefits.moments.new')" color="primary" class="admin" @click="openSidebarRight('loyalty_benefits_moment_schedule_details')">
              <span><i class="fas fa-plus mr-1"/>{{$t('loyalty.Add_benefits_moment')}}</span>
            </CButton>            
            <CButton v-if="checkPermission('loyalty.benefits.moments.templates')" color="primary" class="admin m-0" @click="openLoyaltyBenefitsMomentTemplatePage()">
              <span><i class="fas fa-pen mr-1"/>{{$t('loyalty.Edit_benefits_moment_template')}}</span>
            </CButton>
          </div>
        </CCol>        
      </CRow>
    </CCardHeader>
    <CCardBody class="p-0">
      <div v-if="benefitsMomentsLoading === true">           
        <CRow>
          <CCol cols="12" lg="12">
            <loadingSpinner mode="auto" :content="null"/>
          </CCol>
        </CRow>
      </div>
      <div v-else>        
        <CRow class="benefits_moments_list">
          <CCol cols="12" lg="12" md="12" class="pb-0">
            <b-table class="data_table"
                      :data="benefitsMoments"
                      :striped="true"
                      :hoverable="true"
                      :mobile-cards="true"
                      :paginated="isPaginated"
                      :per-page="itemsPerPage"
                      :current-page.sync="currentPage"
                      :pagination-simple="isPaginationSimple"
                      :pagination-position="paginationPosition">
              
              <template slot-scope="props">
                <b-table-column field="month_year" :label="$t('common.Month')" :searchable="searchEnabled" :sortable="true" width="15%">  
                  <div class="d-flex align-items-center date">
                    <i class="fa-regular fa-calendar"></i>
                    <span>{{props.row.month_year}}</span>
                  </div>                  
                </b-table-column>
                <b-table-column field="total_recipients" :label="$t('common.Recipients')" :searchable="false" :sortable="true" width="15%">  
                  <div v-if="props.row.total_recipients" class="d-flex align-items-center credits">
                    <span><i class="icon far fa-user mr-1"/>{{props.row.total_recipients}} {{props.row.total_recipients !== 1 ? $t('common.recipients') : $t('common.recipient')}}</span>
                  </div>                  
                  <span v-else>-</span>                               
                </b-table-column>
                <b-table-column field="total_points_granted" :label="$t('common.Coins_granted')" :searchable="false" :sortable="true" width="15%">
                  <div v-if="props.row.total_points_granted" class="d-flex align-items-center credits">
                    <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                    <span>{{props.row.total_points_granted}} {{props.row.total_points_granted !== 1 ? $t('common.coins') : $t('common.coin')}}</span>
                  </div>                  
                  <span v-else>-</span>
                </b-table-column>                                
                <b-table-column field="scheduled_for" :label="$t('common.Scheduled_for')" :searchable="false" :sortable="true" width="15%">
                  <div v-if="props.row.scheduled_for" class="d-flex align-items-center date">
                    <i class="fa-regular fa-calendar"></i>
                    <span v-if="props.row.scheduled_for">{{props.row.scheduled_for | moment("DD-MM-YYYY HH:mm")}}</span>
                  </div>
                  <span v-else>-</span>
                </b-table-column>
                <b-table-column field="deadline" :label="$t('common.Deadline')" :searchable="false" :sortable="true" width="15%">
                  <div v-if="props.row.deadline" class="d-flex align-items-center date">
                    <i class="fa-regular fa-calendar"></i>
                    <span v-if="props.row.deadline">{{props.row.deadline | moment("DD-MM-YYYY HH:mm")}}</span>
                  </div>
                  <span v-else>-</span>
                </b-table-column>                                
                <b-table-column field="sent" :label="$t('common.Sent')" :searchable="false" :sortable="true" width="15%">
                  <div v-if="props.row.sent" class="d-flex align-items-center date">
                    <i class="fa-regular fa-calendar"></i>
                    <span v-if="props.row.sent">{{props.row.sent | moment("DD-MM-YYYY HH:mm")}}</span>
                  </div>
                  <span v-else>-</span>
                </b-table-column>
                <b-table-column field="actions" label="" width="10%" :visible="checkPermission('loyalty.benefits.moments.details')">
                  <div class="d-flex justify-content-lg-end">
                    <CButton class="m-0 d-inline-block" color="primary" :to="'/loyalty/benefits/moment/' + props.row.loyalty_benefits_moment_id_external">
                      <i class="fas fa-chevron-right"/>
                    </CButton>
                  </div>
                </b-table-column>                    
              </template>
              <template slot="empty">
                <div class="text-center">
                  <span>{{$t('loyalty.No_benefits_moments_available')}}</span>
                </div>
              </template>                
            </b-table>  
          </CCol>                  
        </CRow>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'loyaltyBenefitsMoments',
  props: ['platformRoles', 'platformPermissions', 'showHeader', 'itemsPerPage'],
  components: {
    loadingSpinner      
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      benefitsMoments: [],      
      benefitsMomentsLoading: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      searchEnabled: true,
      hideDelivered: false
    }
  },
  methods: {
    getLoyaltyBenefitsMoments() {
      // Start the loader
      this.benefitsMomentsLoading = true;
      // Get the Benefits Moments
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/benefits/moments')
      .then(res => {              
        this.benefitsMoments = res.data.data;
        // Enable the pagination if necessary
        (this.benefitsMoments.length > this.itemsPerPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.benefitsMomentsLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openLoyaltyBenefitsMomentTemplatePage() {
      this.$router.push({path: '/loyalty/benefits/moment/template'});
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    }                    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
        
    this.getLoyaltyBenefitsMoments();
    
    this.$bus.$on('loyalty_benefits_moment_added', () => {      
      this.getLoyaltyBenefitsMoments();
    })
  },
  beforeDestroy() {
    this.$bus.$off('loyalty_benefits_moment_added');    
  }
}
</script>